<template>
	<v-card flat class="wr_grey_1 mt-1">
		<v-row no-gutters>
			<!-- Parent column of size 10 (for table display) -->
			<v-col sm="10">
				<v-row>
					<!-- Fixed column -->
					<FixedColumn></FixedColumn>

					<!-- Dynamic columns -->
					<v-col
						:sm="columnSize"
						v-for="(sub_questionnaire, subtopicIndex) in subQuestionnaires"
						:key="subtopicIndex + 'c'"
					>
						<!-- Dynamic rows for each dynamic column -->
						<v-row
							class="px-1"
							v-for="(item, index) in sub_questionnaire.answers"
							:key="index + 'r'"
						>
							<v-card
								width="100%"
								:height="index == 0 ? 85 : 60"
								:class="[
									index == 0 ? 'mb-1' : 'my-1',
									index % 2 == 0 ? 'greyLighten2' : 'deepPurpleLighten4',
								]"
							>
								<v-container fluid class="fill-height">
									<v-row no-gutters>
										<v-col align="center" class="py-0">
											<!-- For heading row-->
											<div v-if="index == 0">
												<v-row no-gutters>
													<!-- Either display textfield or value inside it -->
													<v-col sm="11">
														<v-form
															v-if="edit_mode[sub_questionnaire.id]"
															:ref="'prioritizationForm-' + item.id"
														>
															<v-text-field
																type="text"
																solo
																v-model="item.formated_answer"
																:placeholder="getPlaceholder(subtopicIndex + 1)"
																hide-details
																dense
																color="greenAccent2"
																background-color="white"
																:rules="
																	item.question.required ? [rules.required] : []
																"
																@blur="submitAnswer(item)"
															>
															</v-text-field>
														</v-form>
														<div
															v-else
															class="font-weight-bold subtitle-1 wr_indigo_2--text"
														>
															{{ item.formated_answer }}
														</div>
													</v-col>
													<!-- Either display edit icon or submit -->
													<v-col sm="1" class="cursor-wr">
														<v-icon
															v-if="subQuestionnaires.length > 2"
															@click.stop="
																removeQuestionnaire(sub_questionnaire.id)
															"
															class="close_icon"
															color="error"
														>
															$vuetify.icons.values.cancel
														</v-icon>
														<v-icon
															color="amberDarken1"
															class="mt-1"
															size="30"
															@click="
																item.formated_answer
																	? updateEditMode(
																			sub_questionnaire.id,
																			!edit_mode[sub_questionnaire.id],
																	  )
																	: null
															"
														>
															{{
																$vuetify.icons.values[
																	edit_mode[sub_questionnaire.id]
																		? "check"
																		: "edit"
																]
															}}
														</v-icon>
													</v-col>
												</v-row>
											</div>
											<!-- For other rows -->
											<v-form v-else :ref="'prioritizationForm-' + item.id">
												<v-text-field
													v-model.number="item.formated_answer"
													type="number"
													solo
													hide-details
													color="greenAccent2"
													background-color="white"
													class="input-score-text-wr"
													:rules="
														item.question.required
															? [rules.required, rules.inBetween]
															: [rules.inBetween]
													"
													dense
													:validate-on-blur="true"
													@blur="submitAnswer(item)"
												>
												</v-text-field>
											</v-form>
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-row>
						<!-- Calculation- at the and of each subtopic column -->
						<v-row class="px-1">
							<v-card width="100%" height="80" class="mt-1 deepPurpleLighten2">
								<v-container class="fill-height px-0" fluid>
									<v-row>
										<v-col
											class="white--text font-weight-bold headline"
											align="center"
										>
											{{ calculate(subtopicIndex) }}
										</v-col>
									</v-row>
								</v-container>
							</v-card>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
			<!-- Parent column of size 2 for Add Subtopic button -->
			<v-col sm="2" align="center" class="mt-6">
				<div class="cursor-wr" @click="addQuestionnaire()">
					<div>
						<v-icon color="amberDarken1">$vuetify.icons.values.plus</v-icon>
					</div>
					<div class="wr_indigo_2--text font-weight-bold">
						{{ $t("app.add_subtopic") }}
					</div>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import { convertNumberToAlphabet } from "@/utils/helpers";
import FixedColumn from "./FixedColumn";
import rules from "@/utils/validation";

export default {
	name: "ChooseTopicAltPrioritizationSubtopicsTable",

	data() {
		return {
			rules,
		};
	},

	components: {
		FixedColumn,
	},

	computed: {
		...mapState({
			subQuestionnaires: (state) => state.questionnaire.sub_questionnaires,
			edit_mode: (state) => state.questionnaire.sub_questionnaires_edit_modes,
		}),

		columnSize() {
			switch (this.subQuestionnaires.length) {
				case 0:
					return 12;
				case 1:
					return 6;
				case 2:
					return 4;
				case 3:
					return 3;
				default:
					return 2;
			}
		},
	},

	methods: {
		getPlaceholder(number) {
			try {
				return this.$t("app.placeholder.type_subtopic", {
					alphabet: convertNumberToAlphabet(number),
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		updateEditMode(id, status) {
			try {
				this.$store.commit("questionnaire/MANAGE_SUB_QUESTIONNAIRE_EDIT_MODE", {
					id,
					status,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},

		calculate(index) {
			try {
				let count = 0;
				this.subQuestionnaires[index].answers.forEach((item, index) => {
					/**
					 * Because 0th index is having name, we will not count that.
					 * And, other answer are string when empty, so check if it has value
					 * then only count.
					 */
					if (index !== 0 && item.formated_answer) {
						count += item.formated_answer;
					}
				});
				return count != 0 ? count : null;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async addQuestionnaire() {
			try {
				this.$loader.start();

				if (this.subQuestionnaires.length >= 4) {
					throw new Error("app.ntfy.err.cannot_create_subtopics", {
						cause: "werCustom",
					});
				}

				let response = await this.$store.dispatch(
					"questionnaire/addQuestionnaire",
					{
						questionnaire: "m2_subtopic_selection_p4",
					},
				);
				// Create edit mode for this newly created sub-questionnaire
				this.updateEditMode(response.id, true);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async removeQuestionnaire(questionnaire_id) {
			try {
				this.$loader.start();
				await this.$store.dispatch("questionnaire/removeQuestionnaire", {
					questionnaire_id,
				});
				// After removing any sub questionnaire, we need to get all questionnaires back.
				this.$store.dispatch("questionnaire/list", {
					questionnaire_name: "m2_subtopic_selection_p4",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async submitAnswer(payload) {
			try {
				// If required question's answer is empty(exclude 0) then return;
				if (!this.$refs["prioritizationForm-" + payload.id][0].validate()) {
					throw new Error(
						payload.formated_answer === ""
							? "app.ntfy.err.fill_required_fields"
							: "app.ntfy.err.check_value_range",
						{
							cause: "werCustom",
						},
					);
				}
				await this.$store.dispatch("questionnaire/submitAnswer", {
					questionnaire_id: payload.id,
					answer: payload.formated_answer,
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>

<style scoped>
.close_icon {
	position: absolute;
	top: -9px;
	right: 50%;
}

.input-score-text-wr {
	width: 45px;
}
/** Hide the up and down arrows from input type number */

/* Chrome, Safari, Edge, Opera */
>>> input::-webkit-outer-spin-button,
>>> input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

/* Firefox */
>>> input[type="number"] {
	-moz-appearance: textfield !important;
}
</style>
