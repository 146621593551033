<template>
	<v-dialog :value="dialog" scrollable width="600px" persistent>
		<AppDialog
			:action="{
				label: 'app.send_to_mentor',
				event: 'proceed',
			}"
			@close="$emit('close')"
			@proceed="fireEvent()"
		>
			<template v-slot:dialog-content>
				<v-img :src="require('@/assets/images/coach.png')" width="80"></v-img>

				<div class="title font-weight-bold oflm-wr mt-5">
					{{ $t("app.update_to_mentor") }}
				</div>

				<div
					class="subtitle-1 mt-5"
					v-html="$t('app.choose_topic_summary_submission_will_inform_mentor')"
				></div>

				<div
					class="subtitle-1 mt-5"
					v-html="$t('app.write_explanation_about_not_choosing_topic')"
				></div>

				<v-form ref="sendToMentorForm" class="mt-8">
					<v-textarea
						v-model="answer"
						outlined
						rows="3"
						color="greenAccent2"
						background-color="white"
						row-height="25"
						auto-grow
						:label="$t('app.placeholder.write_explanation')"
						:rules="[rules.required]"
					></v-textarea>
				</v-form>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import rules from "@/utils/validation";
import AppDialog from "@/components/ui/AppDialog.vue";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";
import { mapGetters } from "vuex";

export default {
	name: "ChooseTopicSummarySubmissionConfirmation",

	mixins: [notificationWriteMixin],

	data() {
		return {
			rules,
			answer: null,
		};
	},

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	components: {
		AppDialog,
	},

	computed: {
		...mapGetters({
			userHasMentor: "user/hasMentor",
		}),
	},

	methods: {
		async fireEvent() {
			try {
				if (!this.$refs.sendToMentorForm.validate()) {
					return;
				}

				if (this.userHasMentor) {
					let response = await this.$store.dispatch("notification/send", {
						recipient: "mentor",
						msg_title: this.$t("app.choose_topic.summary.route.title"),
						msg_content: this.answer,
					});

					// Now, Write this message to firebase
					await this.writeNotification(response);
				}

				await this.$emit("confirm");
				this.$refs.sendToMentorForm.reset();
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
