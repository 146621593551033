<template>
	<v-card v-if="current_step" flat class="wr_grey_1">
		<components
			:ref="isSubtopicsStep ? 'subTopicsTable' : null"
			:is="current_step.component_name"
		></components>

		<!-- When user chooses the subtopic "I can't decide" -->
		<SubmissionConfirmationDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			@confirm="sendAnswerToMentor"
			@close="dialog = false"
		></SubmissionConfirmationDialog>
	</v-card>
</template>

<script>
import { questionnaire as questionnaireAPI } from "@/services/api";
import { mapState } from "vuex";
import { prioritizationToolSteps } from "@/utils/steps";
import { findFromArray, findElIndex } from "@/utils/helpers";
import SubtopicsTable from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/prioritizationTool/subtopicsTable/Index";
import ScoreStatistics from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/prioritizationTool/ScoreStatistics";
import SubmissionConfirmationDialog from "@/components/research/modules/defineQuestion/stages/chooseTopic/SubmissionConfirmationDialog.vue";

export default {
	name: "ChooseTopicAltPrioritizationToolPage",

	data() {
		return {
			prioritizationToolSteps,
			dialog: false,
			draft_status: this.$defines.QUESTIONNAIRE_STATUS_DRAFT_STRING,
			approval_status: this.$defines
				.QUESTIONNAIRE_STATUS_WAIT_FOR_APPROVAL_STRING,
		};
	},

	components: {
		SubtopicsTable,
		ScoreStatistics,
		SubmissionConfirmationDialog,
	},

	created() {
		this.readyToListenEvents();
		this.getQuestionnaireData();
	},

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
			subQuestionnaires: (state) => state.questionnaire.sub_questionnaires,
			edit_modes: (state) => state.questionnaire.sub_questionnaires_edit_modes,
			selected_subtopic_id: (state) =>
				state.questionnaire.prioritization_selected_subtopic_id,
			current_step: (state) => state.questionnaire.prioritization_current_step,
		}),

		isSubtopicsStep() {
			return this.current_step.value === 1;
		},
	},

	methods: {
		async readyToListenEvents() {
			this.$eventBus.$on(
				"pt-submit-subtopic-questionnaire",
				async (payload) => {
					try {
						this.$loader.start();
						// Check if all fields has been completed or not
						let valid = await this.validateForms();
						// If completed then mark the questionnaire status approved
						this.changeQuestionnaireStatus(
							this.questionnaire.id,
							this.approval_status,
						);
						// Now, redirect to next step
						this.redirectToRequestedStep("next_step");
					} catch (error) {
						this.$announce.error(error);
					} finally {
						this.$loader.stop();
					}
				},
			);
			this.$eventBus.$on("pt-draft-subtopics-questionnaire", (payload) => {
				try {
					this.$loader.start();

					// Mark the questionnaire status drafted
					this.changeQuestionnaireStatus(
						this.questionnaire.id,
						this.draft_status,
					);

					// Now, redirect to back step.
					this.redirectToRequestedStep("previous_step");
				} catch (error) {
					this.$announce.error(error);
				} finally {
					this.$loader.stop();
				}
			});
			this.$eventBus.$on("pt-finish-work", (payload) => {
				this.finishPrioritizationWork();
			});
		},

		async checkAndSetCurrentStep() {
			this.$store.commit("questionnaire/UPDATE_PRIORITIZATION_CURRENT_STEP", {
				step:
					this.questionnaire.status == this.approval_status
						? prioritizationToolSteps[1]
						: prioritizationToolSteps[0],
			});

			// If this is a subtopic step then create edit modes for sub questionnaires
			if (
				this.isSubtopicsStep &&
				this.subQuestionnaires &&
				this.subQuestionnaires.length
			) {
				await this.createEditModes();
			}
		},

		redirectToRequestedStep(label) {
			let result = findFromArray(
				prioritizationToolSteps,
				"name",
				this.current_step[label],
			);

			this.$store.commit("questionnaire/UPDATE_PRIORITIZATION_CURRENT_STEP", {
				step: result,
			});
		},

		async getQuestionnaireData() {
			try {
				this.$loader.start();

				let questionnaire_name = "m2_subtopic_selection_p4";

				// Get questionnaire
				await this.$store.dispatch("questionnaire/list", {
					questionnaire_name,
				});

				// Get sub questionnaires
				await this.$store.dispatch("questionnaire/getSubQuestionnaires", {
					questionnaire_name,
				});

				// By default, two questionnaires should be presented.
				if (this.subQuestionnaires.length === 1) {
					await this.$store.dispatch("questionnaire/addQuestionnaire", {
						questionnaire: "m2_subtopic_selection_p4",
					});
				}

				await this.checkAndSetCurrentStep();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		createEditModes() {
			this.subQuestionnaires.forEach((element) => {
				// If edit mode is not set already then only set.
				if (!this.edit_modes.hasOwnProperty(element.id)) {
					this.$store.commit(
						"questionnaire/MANAGE_SUB_QUESTIONNAIRE_EDIT_MODE",
						{
							id: element.id,
							status: true,
						},
					);
				}
			});
		},

		async validateForms() {
			let valid = true;
			let parent_ref = this.$refs.subTopicsTable;
			if (!parent_ref) {
				return false;
			}
			for (const form_name in parent_ref.$refs) {
				let form = parent_ref.$refs[form_name];
				valid &= !form.length ? true : await form[0].validate();
			}
			if (!valid) {
				throw new Error("app.ntfy.err.fill_required_fields", {
					cause: "werCustom",
				});
			}
			return valid;
		},

		async changeQuestionnaireStatus(questionnaire_id, status, mutate = true) {
			let payload = {
				questionnaire_id,
				status,
			};
			mutate
				? await this.$store.dispatch("questionnaire/submit", payload)
				: await questionnaireAPI.submit(payload);
		},

		async getQuestionnaire(questionnaire_name) {
			return await this.$store.dispatch("questionnaire/get", {
				questionnaire_name,
			});
		},

		async markOriginQuestionnaireApproved() {
			let p3_questionnaire = await this.getQuestionnaire(
				this.$defines.QUESTIONNAIRE.q40,
			);
			await this.changeQuestionnaireStatus(
				p3_questionnaire.id,
				this.approval_status,
				false,
			);
		},

		async sendAnswerToMentor(payload) {
			try {
				this.$loader.start();

				/**
				 * Now user has send message to mentor, so make that questionnaire
				 * approval from which he came to use prioritization tool.
				 */
				await this.markOriginQuestionnaireApproved();

				this.dialog = false;

				// Redirect to summary route
				this.$router.push({
					name: "ChooseTopicSummary",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		/**----------------------------------------------------------
     * PRIORITIZATION FINISH WORK  METHODS-
     -----------------------------------------------------------*/
		async finishPrioritizationWork() {
			try {
				this.$loader.start();

				await this.checkForErrorCases();

				// -1 id is indicating that user selected, "I can't decide".
				if (this.selected_subtopic_id == -1) {
					this.dialog = true;
					return;
				}

				// Now check, if user comes here after completing summary or not.
				await this.doAnalysisOfSummaryPart();

				/**
				 * Now user have decided his subtopic, so make that questionnaire
				 * approval from which he came to use prioritization tool.
				 */
				await this.markOriginQuestionnaireApproved();

				// Redirect to summary route
				this.$router.push({
					name: "ChooseTopicSummary",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async checkForErrorCases() {
			/**
			 * p2 is the final questionnaire, it its status is approved it means user has
			 * selected the subtopic already.
			 */
			let p2_questionnaire = await this.getQuestionnaire(
				this.$defines.QUESTIONNAIRE.q30,
			);
			if (p2_questionnaire.status == this.approval_status) {
				this.$announce.error(
					new Error("app.ntfy.err.subtopic_already_decided", {
						cause: "werCustom",
					}),
				);

				// Redirect to summary route
				this.$router.push({
					name: "ChooseTopicSummary",
				});

				return;
			}
			/**
			 * p3 is the questionnaire which suggest user for prioritization tool, so if its status
			 * is approved then it means user has sent a message to mentor for mentor's help
			 * without taking help of prioritization tool.
			 */
			let p3_questionnaire = await this.getQuestionnaire(
				this.$defines.QUESTIONNAIRE.q40,
			);
			if (p3_questionnaire.status == this.approval_status) {
				this.$announce.error(
					new Error("app.ntfy.err.already_sent_to_mentor", {
						cause: "werCustom",
					}),
				);

				// Redirect to summary route
				this.$router.push({
					name: "ChooseTopicSummary",
				});

				return;
			}

			// If user has not selected any subtopic before submission.
			if (!this.selected_subtopic_id) {
				throw new Error("app.ntfy.err.select_subtopic", {
					cause: "werCustom",
				});
			}
		},

		async doAnalysisOfSummaryPart() {
			// Get p1 questionnaire
			let p1_questionnaire = await this.getQuestionnaire(
				this.$defines.QUESTIONNAIRE.q20,
			);

			// Now, find single choice question's answer from it.
			let result = p1_questionnaire.answers.find(
				(item) => item.question.type === "single_choice",
			);

			if (!result.formated_answer) {
				throw new Error("app.ntfy.err.summary_not_completed", {
					cause: "werCustom",
				});
			}

			// Check user given answer is yes or no.
			let answer_pos = findElIndex(
				result.question.options,
				"id",
				result.formated_answer,
			);

			// If user has given no, then we have to set this answer as "yes".
			if (answer_pos > 0) {
				// The first option would always have the answer "yes"
				let should_be_answer = result.question.options[0];

				// and submit this answer in order to proceed
				await questionnaireAPI.submitAnswer({
					questionnaire_id: result.id,
					answer: should_be_answer.id,
				});
			}

			// Now, make p1 questionnaire's status approval
			if (p1_questionnaire.status !== this.approval_status) {
				this.changeQuestionnaireStatus(
					p1_questionnaire.id,
					this.approval_status,
					false,
				);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("pt-submit-subtopic-questionnaire");
		this.$eventBus.$off("pt-draft-subtopics-questionnaire");
		this.$eventBus.$off("pt-finish-work");
	},
};
</script>
