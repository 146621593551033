<template>
	<v-col :sm="columnSize" v-if="questionnaire">
		<!-- Run Loop for Questions -->
		<v-row
			class="px-1"
			v-for="(item, index) in questionnaire.answers"
			:key="index"
		>
			<v-card
				width="100%"
				:height="index == 0 ? 85 : 60"
				:class="[
					index == 0 ? 'mb-1' : 'my-1',
					index % 2 == 0 ? 'greyLighten2' : 'deepPurpleLighten4',
				]"
			>
				<v-container class="fill-height px-0" fluid>
					<v-card-text
						:class="[
							'wr_indigo_2--text',
							index == 0 ? 'subtitle-1' : 'body-2',
							index == 0 ? 'font-weight-bold' : null,
							'text-truncate',
						]"
						:align="index == 0 ? 'center' : null"
					>
						{{ item.question.name }}
					</v-card-text>
				</v-container>
			</v-card>
		</v-row>
		<!-- Score label at the and of all questions -->
		<v-row class="px-1">
			<v-card width="100%" height="80" class="mt-1 deepPurpleLighten2">
				<v-container class="fill-height px-0" fluid>
					<v-row>
						<v-col
							class="text-uppercase white--text font-weight-bold subtitle-1"
							align="center"
						>
							{{ $t("app.score") }}
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-row>
		<!-- Add aspects button to add more questions-->
		<!-- <v-row class="mt-4 cursor-wr">
      <v-card flat width="100%">
        <v-card-text class="wr_indigo_2--text font-weight-bold">
          <v-icon color="amberDarken1"
            >$vuetify.icons.values.plus</v-icon
          >
          {{ $t("app.add_aspects") }}
        </v-card-text>
      </v-card>
    </v-row> -->
	</v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "ChooseTopicAltPrioritizationSubtopicsFixedColumn",

	computed: {
		...mapState({
			questionnaire: (state) => state.questionnaire.list,
			subQuestionnaires: (state) => state.questionnaire.sub_questionnaires,
		}),

		columnSize() {
			switch (this.subQuestionnaires.length) {
				case 0:
					return 12;
				case 1:
					return 6;
				case 3:
					return 3;
				default:
					return 4;
			}
		},
	},
};
</script>
