<template>
	<v-row no-gutters align="center" justify="center" class="fill-height">
		<!-- (1) Columns, equal in number of subtopics + one static -->
		<template v-for="(item, index) in options">
			<v-card
				:key="index"
				width="290"
				height="270"
				align="center"
				justify="center"
				:class="[
					'cursor-wr d-inline-block mx-3 priority-card-wr',
					{ 'selected-subtopic-wr': selected_subtopic_id == item.id },
				]"
				@click="markSubtopicSelected(item.id)"
			>
				<!-- (2) Check icon to display for selected subtopic -->
				<v-icon
					v-if="selected_subtopic_id == item.id"
					color="greenAccent2"
					:style="styleObj"
				>
					$vuetify.icons.values.check_circle
				</v-icon>

				<v-container class="fill-height">
					<v-row no-gutters>
						<v-col align="center">
							<!-- (3.1) For non-graph static card -->
							<div v-if="item.id == -1" :style="{ height: '150px' }">
								<v-container class="fill-height">
									<v-row no-gutters>
										<v-col align="center">
											<v-img
												:src="require(`@/assets/images/question-mark.png`)"
												width="90"
											></v-img>
										</v-col>
									</v-row>
								</v-container>
							</div>

							<!-- (3.2) For graph cards -->
							<div v-else class="greyLighten4 priority-score-parent-wr">
								<div
									:style="{
										height: (150 * item.score) / maxScore + 'px',
										width: '85px',
										background: generateRandomColor(),
									}"
								>
									<div class="py-2 priority-score-text-wr">
										<div class="headline font-weight-bold">
											{{ item.score }}
										</div>
										<div class="body-2">{{ $t("app.score") }}</div>
									</div>
								</div>
							</div>
							<!-- (4) Title of every card -->
							<v-card-text
								class="pt-6 title card-title-wr font-weight-black black--text text-truncate"
							>
								{{ item.title }}
							</v-card-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</template>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import { convertToNum, generateRandomColor } from "@/utils/helpers";

export default {
	name: "ChooseTopicAltPrioritizationScoreStatistics",

	data() {
		return {
			generateRandomColor,
		};
	},

	computed: {
		...mapState({
			subTopics: (state) => state.questionnaire.sub_questionnaires,
			selected_subtopic_id: (state) =>
				state.questionnaire.prioritization_selected_subtopic_id,
		}),

		styleObj() {
			return {
				position: "absolute",
				[this.$vuetify.rtl ? "right" : "left"]: 0,
			};
		},

		options() {
			let data = [];
			this.subTopics.forEach((subTopic) => {
				let obj = {};
				let sum = 0;

				obj.id = subTopic.id;
				// 0th index answer will always be the name of subtopic
				obj.title = subTopic.answers[0].formated_answer;
				// Sum all answers given by user
				subTopic.answers.forEach((item, index) => {
					if (index !== 0) {
						sum += convertToNum(item.formated_answer);
					}
				});
				obj.score = sum;

				data.push(obj);
			});
			// One static option
			data.push({
				id: -1,
				title: this.$t("app.can't_decide"),
			});
			return data;
		},

		maxScore() {
			let data = [];
			this.subTopics.forEach((subTopic) => {
				let sum = 0;
				subTopic.answers.forEach((item, index) => {
					if (index !== 0) {
						sum += convertToNum(item.formated_answer);
					}
				});
				data.push(sum);
			});
			return Math.max(...data);
		},
	},

	methods: {
		markSubtopicSelected(subtopic_id) {
			this.$store.commit("questionnaire/MARK_SUBTOPIC_SELECTED", {
				subtopic_id,
			});
		},
	},
};
</script>

<style scoped>
.selected-subtopic-wr {
	border: 3px solid var(--v-greenAccent2-base);
}
</style>
